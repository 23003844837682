import React from 'react';
import PublicPage from './PublicPage';
import Config from '../../config';
import { AppContext, isLoggedIn, isSuperuser } from '../../context/global';
import PropTypes from 'prop-types';
import EcommerceTracking from '../../sales/payment/EcommerceTracking';
import { AlertError } from '../components/Alert';
import Content from './Content';

export default class PrivatePage extends React.Component {
  constructor(props) {
    super(props);
    window.isPrivatePage = true;
  }

  render() {
    if (!isLoggedIn()) {
      window.ffShowPageLoading();
      document.getElementById('app').remove();
      const next = window.location.pathname;
      window.location = `${Config.djangoHost}accounts/login?next=${next}`;
      return null;
    } else {
      window.ffHidePageLoading();
    }

    if (this.props.isAdminOnly && !isSuperuser()) {
      return (
        <PublicPage {...this.props} title="Permissão negada">
          <Content>
            <AlertError>Você não tem permissão para acessar essa página.</AlertError>
          </Content>
        </PublicPage>
      );
    }

    return (
      <PublicPage {...this.props}>
        {this.props.children}
        {this.props.loadTrackers && <EcommerceTracking />}
      </PublicPage>
    );
  }
}

PrivatePage.propTypes = {
  title: PropTypes.string.isRequired,
  hasAside: PropTypes.bool,
  hasLeftSidebar: PropTypes.bool,
  leftSidebarCollapsed: PropTypes.bool,
  navbar: PropTypes.element,
  isAdminOnly: PropTypes.bool,
  loadTrackers: PropTypes.bool,
};

PrivatePage.defaultProps = {
  loadTrackers: true,
};

PrivatePage.contextType = AppContext;
