import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import Config from '../../../config';
import { AppContext } from '../../../context/global';
import Ajax from '../../ajax';
import Modal from '../../containers/Modal';
import Waiting from '../../containers/Waiting';
import { getIframeDocument } from '../../htmlutils';
import storage from '../../storage';
import { AlertError } from '../Alert';
import AnnotatorEditor from './AnnotatorEditor';
import './PDFViewer.css';

class PDFViewerPdfjs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfDocument: null,
      page: 1,
      scale: 1,
      zoomSelect: 1,
      processing: true,
      errorMessage: null,
      annotation: null,
      annotations: [],
      annotationsByPage: {},
    };
    this.iframeRef = React.createRef();
    this.pdfViewerContainer = null;
    this.PDFViewerApplication = null;
    this.annotatorEditor = null;
    this.annotatorLib = null;
    this.annotatorApp = null;
    this.highlighter = null;
    this._mounted = false;
    this.findPdfViewer = this.findPdfViewer.bind(this);
    this.configureAnnotator = this.configureAnnotator.bind(this);
    this.annotatorHooks = this.annotatorHooks.bind(this);
    this.setIframeHeight = this.setIframeHeight.bind(this);
    this.redrawAnnotations = this.redrawAnnotations.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.findPdfViewer();

    window.addEventListener('resize', this.setIframeHeight);
  }

  componentWillUnmount() {
    this._mounted = false;
    window.removeEventListener('resize', this.setIframeHeight);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.annotation && this.state.annotation) {
      setTimeout(
        () => document.getElementById('annotation-comment').focus(),
        300
      );
    }
  }

  setIframeHeight() {
    const { current: iframe } = this.iframeRef;
    const navBar = document.querySelector('.navbar-fixed-top');
    if (iframe && document.body.offsetHeight && navBar) {
      iframe.style.height =
        document.body.offsetHeight - navBar.offsetHeight - 6 + 'px';
    }
  }

  findPdfViewer() {
    const { current: iframe } = this.iframeRef;
    if (iframe) {
      this.setIframeHeight();
      const iframeDoc = getIframeDocument(iframe);
      iframeDoc.cookie = document.cookie;

      if (!this.pdfViewerContainer) {
        this.pdfViewerContainer = iframeDoc.getElementById('viewerContainer');
      }

      if (!this.annotatorLib) {
        this.annotatorLib = iframe.contentWindow.annotator;
      }

      if (!this.PDFViewerApplication) {
        this.PDFViewerApplication = iframe.contentWindow.PDFViewerApplication;
      }

      if (this.PDFViewerApplication && this.PDFViewerApplication.eventBus) {
        this.PDFViewerApplication.eventBus.on('textlayerrendered', (pageObj) =>
          this.redrawAnnotations(pageObj)
        );
        this.PDFViewerApplication.eventBus.on('pagesinit', () =>
          this.setState((state) => ({ ...state, processing: false }))
        );

        this.configureAnnotator();
        return;
      }
    }

    setTimeout(this.findPdfViewer, 100);
  }

  configureAnnotator() {
    this.highlighter = new this.annotatorLib.ui.highlighter.Highlighter(
      getIframeDocument(this.iframeRef.current).body
    );
    this.annotatorApp = this.iframeRef.current.contentWindow.app;

    this.annotatorApp.include(this.annotatorLib.storage.http, {
      prefix: this.props.annotationPrefix,
      urls: {
        create: this.props.annotationCreateURL,
        update: this.props.annotationUpdateURL,
        destroy: this.props.annotationDestroyURL,
        search: this.props.annotationSearchURL,
      },
      onError: (msg, xhr) => {
        this.setState((state) => ({
          ...state,
          errorMessage: xhr.responseJSON.detail,
          processing: false,
        }));
      },
    });

    this.annotatorApp.include(this.annotatorLib.authz.acl);
    this.annotatorApp.include(this.annotatorHooks, { props: this.props });
    this.annotatorApp.start().then(() => {
      this.annotatorApp.annotations.store.setHeader(
        'X-CSRFToken',
        storage.getCookie('csrftoken2')
      );
      // Mokey patch to add xhrFields.
      const _apiRequestOptions =
        this.annotatorApp.annotations.store._apiRequestOptions;
      this.annotatorApp.annotations.store._apiRequestOptions = function () {
        const options = _apiRequestOptions.apply(this, arguments);
        options.xhrFields = { withCredentials: true };
        return options;
      };
      // End monkey patch
      this.annotatorApp.ident.identity = this.context.user;
      this.annotatorEditor =
        this.iframeRef.current.contentWindow.annotatorEditor;
      const isS3 = this.props.url.indexOf('s3.amazonaws') > -1;
      this.PDFViewerApplication.open(this.props.url, {
        withCredentials: isS3 ? false : true,
      });
    });
  }

  redrawAnnotations(pageObj) {
    const { annotationPrefix, annotationSearchURL } = this.props;
    const { pageNumber } = pageObj;

    if (!this.state.annotationsByPage[pageObj.pageNumber]) {
      Ajax.get(
        `${annotationPrefix}${annotationSearchURL}&page=${pageNumber}`
      ).then((response) => {
        const { rows: annotations } = response;
        this.annotatorApp.runHook('annotationsLoaded', [annotations]);
      });
    } else {
      const annotations = this.state.annotationsByPage[pageNumber];
      annotations.forEach((ann) => this.highlighter.undraw(ann));
      this.annotatorApp.runHook('annotationsLoaded', [annotations]);
    }
  }

  annotatorHooks(options) {
    const that = this;
    const props = options.props;

    const copyExtraAnnotationProps = (annotation) => {
      Object.keys(props.annotationExtraProps).forEach((key) => {
        annotation[key] = props.annotationExtraProps[key];
      });
      annotation.json = JSON.stringify(annotation);
      annotation.page = this.PDFViewerApplication.page;
    };

    return {
      start: (app) => {
        that.annotatorApp = app;
      },
      annotationsLoaded: (annotations) => {
        if (that._mounted) {
          if (props.annotationsLoaded) {
            props.annotationsLoaded(annotations);
          }
          annotations.forEach((ann) => {
            // Set the same user object, so authz works correctly.
            if (ann.user.email === that.context.user.email) {
              ann.user = that.context.user;
            }
            that.setHighlightColor(ann);
          });
          that.setState((state) => ({
            ...state,
            processing: false,
            annotations,
          }));
        }
      },
      beforeAnnotationCreated: (annotation) => {
        if (that._mounted) {
          copyExtraAnnotationProps(annotation);
          that.setState((state) => ({ ...state, annotation }));
        }
      },
      annotationCreated: (annotation) => {
        if (that._mounted) {
          if (props.annotationCreated) {
            props.annotationCreated(annotation);
          }
          // Set the same user object, so authz works correctly.
          if (
            !annotation.user ||
            annotation.user.email === that.context.user.email
          ) {
            annotation.user = that.context.user;
          }
          that.setState((state) => ({
            ...state,
            annotation: null,
            annotations: [...state.annotations].concat([annotation]),
          }));
          that.setHighlightColor(annotation);
        }
      },
      beforeAnnotationUpdated: (annotation) => {
        if (that._mounted) {
          copyExtraAnnotationProps(annotation);
          that.setState((state) => ({ ...state, annotation }));
        }
      },
      annotationUpdated: (annotation) => {
        if (that._mounted) {
          if (props.annotationUpdated) {
            props.annotationUpdated(annotation);
          }
          that.setState((state) => ({ ...state, annotation: null }));
          that.setHighlightColor(annotation);
        }
      },
      beforeAnnotationDeleted: (annotation) => {
        if (that._mounted) {
          if (props.beforeAnnotationDeleted) {
            props.beforeAnnotationDeleted(annotation);
          }
        }
      },
      annotationDeleted: (annotation) => {
        if (that._mounted) {
          if (props.annotationDeleted) {
            props.annotationDeleted(annotation);
          }
          that.setState((state) => ({
            ...state,
            annotation: null,
            annotations: state.annotations.filter(
              (a) => a.id !== annotation.id
            ),
          }));
        }
      },
    };
  }

  setHighlightColor(annotation) {
    const elements = this.pdfViewerContainer.querySelectorAll(
      `[data-annotation-id="${annotation.id}"]`
    );
    [].forEach.call(elements, (el) => {
      el.style.backgroundColor = annotation.color;
    });
  }

  render() {
    const { i18n, title } = this.props;
    return (
      <>
        <Waiting isProcessing={this.state.processing}>
          <iframe
            ref={this.iframeRef}
            frameBorder="0"
            width="100%"
            src={`/lib/pdfjs/web/viewer.html?file=''&pagemode=none`}
          />
        </Waiting>

        <Modal
          show={!!this.state.errorMessage}
          onCancel={() =>
            this.setState((state) => ({ ...state, errorMessage: null }))
          }>
          <AlertError>{this.state.errorMessage}</AlertError>
        </Modal>

        {this.state.annotation && (
          <AnnotatorEditor
            annotation={this.state.annotation}
            annotatorApp={this.annotatorApp}
            annotatorEditor={this.annotatorEditor}
            onCancel={() =>
              this.setState((state) => ({ ...state, annotation: null }))
            }
            onSave={() =>
              this.setState((state) => ({ ...state, annotation: null }))
            }
            fetchURL={
              this.props.annotationPrefix + this.props.annotationFetchURL
            }
            createURL={
              this.props.annotationPrefix + this.props.annotationCreateURL
            }
            updateURL={
              this.props.annotationPrefix + this.props.annotationUpdateURL
            }
          />
        )}
      </>
    );
  }
}

PDFViewerPdfjs.propTypes = {
  withCredentials: PropTypes.bool,
  title: PropTypes.string,
  authors: PropTypes.array,
  showAnnotations: PropTypes.bool,
  /**
   * The PDF url.
   */
  url: PropTypes.string.isRequired,
  /**
   * Function called after page changed. Receives the page number.
   */
  onPageChange: PropTypes.func,
  annotationPrefix: PropTypes.string,
  annotationCreateURL: PropTypes.string,
  annotationFetchURL: PropTypes.string,
  annotationUpdateURL: PropTypes.string,
  annotationDestroyURL: PropTypes.string,
  annotationSearchURL: PropTypes.string,
  /**
   * Extra proeprties to be set in the annotation object during
   * REST operations.
   */
  annotationExtraProps: PropTypes.object,
  annotationsLoaded: PropTypes.func,
  beforeAnnotationCreated: PropTypes.func,
  annotationCreated: PropTypes.func,
  beforeAnnotationUpdated: PropTypes.func,
  annotationUpdated: PropTypes.func,
  beforeAnnotationDeleted: PropTypes.func,
  annotationDeleted: PropTypes.func,
};

PDFViewerPdfjs.defaultProps = {
  annotationPrefix: `${Config.apiHost}`,
  withCredentials: true,
  showAnnotations: true,
};

PDFViewerPdfjs.contextType = AppContext;

export default withNamespaces()(PDFViewerPdfjs);
