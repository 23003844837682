import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import LoadTrackers from '../../integrations/LoadTrackers';

const Navbar = React.lazy(() => import('../components/navbar/Navbar'));
const LeftSidebar = React.lazy(() => import('../components/LeftSidebar'));

export default class PublicPage extends React.Component {
  constructor(props) {
    super(props);
    this.beagleApp = null;
  }

  componentDidMount() {
    import('../beagle' /* webpackChunkName: 'beagleapp' */).then((module) => {
      this.beagleApp = module.BeagleApp();
      this.beagleApp.init();
    });
  }

  componentDidUpdate() {
    if (this.beagleApp) {
      this.beagleApp.init();
    }
  }

  render() {
    window.ffHidePageLoading();

    const classes = ['be-wrapper'];

    if (this.props.hasAside) {
      classes.push('be-aside');
    }

    if (this.props.hasLeftSidebar) {
      classes.push('be-collapsible-sidebar be-fixed-sidebar');
    } else {
      classes.push('be-nosidebar-left');
    }

    if (this.props.classes) {
      classes.push(` ${this.props.classes}`);
    }

    if (document.body.classList) {
      document.body.classList.remove('be-splash-screen');
    }

    document.title = `FastFormat ${this.props.title ? '-' : ''} ${this.props.title}`;

    let navBar = this.props.navbar;
    if (!navBar) {
      navBar = (
        <Suspense>
          <Navbar title={this.props.title} />
        </Suspense>
      );
    }

    return (
      <div className={classes.join(' ')}>
        {navBar}
        {this.props.hasLeftSidebar && (
          <Suspense>
            <LeftSidebar title={this.props.title} collapsed={this.props.leftSidebarCollapsed} />
          </Suspense>
        )}
        {this.props.children}
        {this.props.loadTrackers && <LoadTrackers />}
      </div>
    );
  }
}

PublicPage.propTypes = {
  title: PropTypes.string.isRequired,
  hasAside: PropTypes.bool,
  hasLeftSidebar: PropTypes.bool,
  leftSidebarCollapsed: PropTypes.bool,
  navbar: PropTypes.element,
  classes: PropTypes.string,
  loadTrackers: PropTypes.bool,
};

PublicPage.defaultProps = {
  hasAside: false,
  hasLeftSidebar: true,
  loadTrackers: true,
};
