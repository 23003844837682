import React from 'react';
import Config from '../config';

export default class FacebookPixel {
  static sendEcommerce(checkout) {
    try {
      if (Config.isDevelopment || Config.isStaging || Config.isBrandEnabled) {
        console.warn('FacebookPixel disabled because it is on development mode, brandEnabled or staging');
        return false;
      }

      if (typeof fbq !== 'undefined') {
        console.log('facebook pixel was loaded.');
        fbq('track', 'Purchase', {
          value: checkout.total,
          content_name: checkout.plan_name,
          currency: checkout.currency_code,
        });
      } else {
        console.warn('facebook pixel was not loaded!');
      }
    } catch (error) {
      console.error(error);
    }
  }

  static sendViewContent() {
    try {
      if (Config.isDevelopment || Config.isStaging || Config.isBrandEnabled) {
        console.warn('FacebookPixel disabled because it is on development mode, brandEnabled or staging');
        return false;
      }

      if (typeof fbq !== 'undefined') {
        console.log('facebook pixel was loaded.');
        fbq('track', 'ViewContent');
      } else {
        console.warn('facebook pixel was not loaded!');
      }
    } catch (error) {
      console.error(error);
    }
  }

  static sendPageView() {
    try {
      if (Config.isDevelopment || Config.isStaging || Config.isBrandEnabled) {
        console.warn('FacebookPixel disabled because it is on development mode, brandEnabled or staging');
        return false;
      }

      if (typeof fbq !== 'undefined') {
        console.log('facebook pixel was loaded.');
        fbq('track', 'PageView');
      } else {
        console.warn('facebook pixel was not loaded!');
      }
    } catch (error) {
      console.error(error);
    }
  }
}
