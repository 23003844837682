const NOT_INITIATED_MSG = 'Google analytics is not initiated.';

function logEcommerceGA4(user, checkout) {
  if (typeof gtag === 'undefined') {
    console.warn('GA4 not initialized.');
    return;
  }
  console.log('Tracking checkout with GA4:', checkout);
  gtag('event', 'purchase', {
    transaction_id: checkout.id,
    value: checkout.total,
    currency: checkout.currency_code,
    // tax: 0,
    // shipping: 0,
    // coupon: "SUMMER_SALE",
    items: [
      // If someone purchases more than one item, you can add those items to the items array
      {
        item_id: checkout.id,
        item_name: checkout.plan_name,
        price: checkout.total,
        quantity: 1,
        // affiliation: "Google Merchandise Store",
        // coupon: "SUMMER_FUN",
        // discount: 2.22,
        // index: 0,
        // item_brand: "Google",
        // item_category: "Apparel",
        // item_category2: "Adult",
        // item_category3: "Shirts",
        // item_category4: "Crew",
        // item_category5: "Short sleeve",
        // item_list_id: "related_products",
        // item_list_name: "Related Products",
        // item_variant: "green",
        // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      },
    ],
  });
}

export default class GoogleAnalytics {
  static logEcommerce(user, checkout) {
    logEcommerceGA4(user, checkout);
  }
}
