import React, {Component} from 'react';
import PropTypes from "prop-types";
import {logError} from "../ajax";
import {AppContext} from "../../context/global";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        logError(this.context.user, error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            if (this.props.featureName) {
                return (
                    <p className="text-danger">
                        <i className="icon mdi mdi-close-circle" /> A funcionalidade de <b>{this.props.featureName}</b> está indisponível.
                    </p>
                );
            } else {
                return (
                    <p className="text-danger">
                        <i className="icon mdi mdi-close-circle" /> Funcionalidade indisponível.
                    </p>
                );
            }
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    featureName: PropTypes.string,
};

ErrorBoundary.contextType = AppContext;

export default ErrorBoundary;
