import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  render() {
    const { label, help, title, asFormGroup, ...rest } = this.props;

    const check = (
      <>
        <div className="be-checkbox" title={title}>
          <input type="checkbox" {...rest} />
          <label htmlFor={this.props.id}>{label}</label>
        </div>
        {help && <p className="help-block">{help}</p>}
      </>
    );

    if (asFormGroup) {
      return <div className="form-group">{check}</div>;
    } else {
      return check;
    }
  }
}

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  help: PropTypes.string,
  asFormGroup: PropTypes.bool,
};

Checkbox.defaultProps = {
  asFormGroup: true,
};

export default Checkbox;
