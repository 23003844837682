import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from './ErrorBoundary';
import { withNamespaces } from 'react-i18next';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reduceAside: false,
    };
  }

  renderHideButton() {
    const { i18n } = this.props;
    return (
      <span
        role="button"
        className="btn btn-sm btn-default btn-block"
        title={this.state.reduceAside ? i18n.t('Exibir menu de marcadores') : i18n.t('Esconder menu de marcadores')}
        style={{ borderColor: 'white', textAlign: 'right' }}
        onClick={(e) => {
          this.setState((state) => ({
            ...state,
            reduceAside: !state.reduceAside,
          }));
        }}
      >
        <i style={{ fontSize: '25px' }} className={`icon mdi mdi-caret-${this.state.reduceAside ? 'right' : 'left'}`} />
      </span>
    );
  }

  render() {
    const classes = ['be-content'];

    if (this.props.aside || this.props.noPadding) {
      classes.push('be-no-padding');
    }

    const pageAsideStyle = {};
    const contentStyle = {};

    if (this.state.reduceAside) {
      const width = '25px';
      pageAsideStyle.width = width;
      contentStyle.marginLeft = width;
    }

    return (
      <div className={classes.join(' ')}>
        {this.props.aside && (
          <aside className="page-aside" style={pageAsideStyle}>
            {this.renderHideButton()}
            <div className="ff-scrollbar" style={{ display: this.state.reduceAside ? 'none' : '' }}>
              <ErrorBoundary>{this.props.aside}</ErrorBoundary>
            </div>
          </aside>
        )}
        <div className="main-content container-fluid" style={contentStyle}>
          <ErrorBoundary>{this.props.children || null}</ErrorBoundary>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
  aside: PropTypes.element,
  noPadding: PropTypes.bool,
};

Content.defaultProps = {
  noPadding: false,
};

export default withNamespaces()(Content);
