import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FormGroup extends Component {
  getErrors() {
    if (Array.isArray(this.props.error)) {
      return this.props.error.map((e, idx) => {
        return (
          <p key={idx} className="help-block">
            {e}
          </p>
        );
      });
    } else if (this.props.error) {
      return <p className="help-block">{this.props.error}</p>;
    }
  }

  render() {
    const { id, error, label, help, helpAsText } = this.props;
    let hasError = false;
    if (Array.isArray(error)) {
      hasError = error.filter((e) => !!e).length > 0;
    } else {
      hasError = !!error;
    }

    return (
      <div className={`form-group ${hasError ? 'has-error' : ''}`} title={help} style={{ marginBottom: '20px' }}>
        {label && (
          <label className="" htmlFor={id}>
            {label}
            &nbsp;
            {help && !helpAsText && <i className="icon mdi mdi-info-outline" title={help} />}
          </label>
        )}
        {this.props.children}
        {helpAsText && <p className="help-block">{help}</p>}
        {this.getErrors()}
      </div>
    );
  }
}

FormGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  help: PropTypes.string,
  helpAsText: PropTypes.bool,
  /**
   * An error message or a list of error messages.
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default FormGroup;
