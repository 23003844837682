
export function clearDjangoValidationMessages() {
    $('p.has-error').remove();
    $('.has-error').removeClass('has-error');
}


export function showDjangoValidationMessages(messagesObject) {
    clearDjangoValidationMessages();

    if (!messagesObject) {
        return;
    }

    const names = Object.keys(messagesObject);
    names.forEach(name => {
        const $input = $(`[name="${name}"]`);
        $input.closest('.form-group').addClass('has-error');
        const msgs = messagesObject[name];
        if (msgs && msgs.forEach) {
            msgs.forEach(msg => {
                const help = `<p class="help-block has-error">${msg}</p>`;
                $input.closest('.form-group').append(help);
            });
        }
    });

    const firstWithError = $('.has-error').find('input').first()[0];
    if (firstWithError) {
        firstWithError.focus();
        firstWithError.scrollIntoView();
    }
}