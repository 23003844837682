import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from './FormGroup';

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLineNumber: null,
      totalLines: 0,
    };
    this.textareaRef = React.createRef();
    this.onLineNumberChange = this.onLineNumberChange.bind(this);
  }

  onLineNumberChange() {
    if (this.props.showSelectedLineNumber) {
      const { current } = this.textareaRef;
      if (current) {
        const line = current.value.substr(0, current.selectionStart).split('\n').length;
        const totalLines = current.value.split('\n').length;
        this.setState((state) => ({
          ...state,
          currentLineNumber: line,
          totalLines,
        }));
      }
    }
  }

  render() {
    const { label, help, id, helpAsText, value, error, showSelectedLineNumber, showCharactersCounter, ...rest } =
      this.props;

    return (
      <FormGroup error={error} help={help} helpAsText={helpAsText} label={label} id={id}>
        <textarea
          id={id}
          {...rest}
          className="form-control"
          value={value}
          ref={this.textareaRef}
          onClick={this.onLineNumberChange}
          onKeyUp={this.onLineNumberChange}
        />
        {showSelectedLineNumber && this.state.currentLineNumber && (
          <>
            <small className="text-muted">
              {this.state.currentLineNumber}/{this.state.totalLines}{' '}
            </small>
          </>
        )}

        {showCharactersCounter && this.props.value && (
          <small className="text-muted">({this.props.value && this.props.value.length})</small>
        )}
      </FormGroup>
    );
  }
}

Textarea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string,
  help: PropTypes.string,
  helpAsText: PropTypes.bool,
  /**
   * An error message or a list of error messages.
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * A function that receives the current selected line number.
   */
  showSelectedLineNumber: PropTypes.bool,
  showCharactersCounter: PropTypes.bool,
};

export default Textarea;
