import React from 'react';
import PropTypes from 'prop-types';
import pdfjs from 'pdfjs-dist/webpack';
import 'pdfjs-dist/web/pdf_viewer.css';
import annotator from 'annotator';
import Waiting from '../../containers/Waiting';
import Config from '../../../config';
import './PDFViewer.css';
import storage from '../../storage';
import { withNamespaces } from 'react-i18next';
import Modal from '../../containers/Modal';
import { AlertError, AlertInfo } from '../Alert';
import { AppContext } from '../../../context/global';
import AnnotatorEditor from './AnnotatorEditor';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import ReflexViewport from '../ReflexViewport';
import AnnotationPanel from './AnnotationPanel';

class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfDocument: null,
      page: 1,
      scale: 1,
      zoomSelect: 1,
      processing: true,
      errorMessage: null,
      annotation: null,
      annotations: [],
    };
    this.pdfViewer = null;
    this.pdfViewerContainer = null;
    this.annotatorEditor = null;
    this.annotatorApp = null;
    this._mounted = false;
    this.onZoomInClick = this.onZoomInClick.bind(this);
    this.onZoomOutClick = this.onZoomOutClick.bind(this);
    this.annotatorHooks = this.annotatorHooks.bind(this);
    this.addAnnotationInPanel = this.addAnnotationInPanel.bind(this);
    this.updateAnnotationInPanel = this.updateAnnotationInPanel.bind(this);
    this.removeAnnotationInPanel = this.removeAnnotationInPanel.bind(this);
    this.sortAnnotations = this.sortAnnotations.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchPdfDocument();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.annotation && this.state.annotation) {
      setTimeout(
        () => document.getElementById('annotation-comment').focus(),
        300
      );
    }
  }

  fetchPdfDocument() {
    const params = {
      url: this.props.url,
      withCredentials: this.props.withCredentials,
    };
    pdfjs.getDocument(params).then((document) => {
      this.setState(
        (state) => ({
          ...state,
          pdfDocument: document,
        }),
        this.loadAllPages
      );
    });
  }

  loadAllPages(scale) {
    if (this.state.pdfDocument) {
      this.removePages();
      for (let i = 1; i <= this.state.pdfDocument.numPages; i++) {
        this.loadPage(i, scale);
      }
    }
  }

  loadPage(pageNum, scale) {
    this.createEmptyPage(pageNum);
    this.state.pdfDocument.getPage(pageNum).then((pdfPage) => {
      let page = document.getElementById(`pageContainer${pageNum}`);
      let canvas = page.querySelector('canvas');
      let wrapper = page.querySelector('.canvasWrapper');
      let container = page.querySelector('.textLayer');
      let canvasContext = canvas.getContext('2d');
      let viewport = null;
      if (!scale) {
        viewport = pdfPage.getViewport(1);
        scale = this.pdfViewerContainer.clientWidth / viewport.width - 0.03;
        viewport = pdfPage.getViewport(scale);
      } else {
        viewport = pdfPage.getViewport(scale);
      }
      canvas.width = viewport.width * 2;
      canvas.height = viewport.height * 2;
      page.style.width = `${viewport.width}px`;
      page.style.height = `${viewport.height}px`;
      wrapper.style.width = `${viewport.width}px`;
      wrapper.style.height = `${viewport.height}px`;
      container.style.width = `${viewport.width}px`;
      container.style.height = `${viewport.height}px`;

      pdfPage.render({
        canvasContext,
        viewport,
      });

      const that = this;
      pdfPage.getTextContent().then((textContent) => {
        const task = pdfjs.renderTextLayer({
          textContent,
          container,
          viewport,
          textDivs: [],
        });

        if (pageNum === that.state.pdfDocument.numPages) {
          task.promise.then(() => {
            that.configureAnnotator();
          });
        }

        page.setAttribute('data-loaded', 'true');
      });
    });
  }

  createEmptyPage(num) {
    let page = document.createElement('div');
    let canvas = document.createElement('canvas');
    let wrapper = document.createElement('div');
    let textLayer = document.createElement('div');
    page.className = 'page';
    wrapper.className = 'canvasWrapper';
    textLayer.className = 'textLayer';
    page.setAttribute('id', `pageContainer${num}`);
    page.setAttribute('data-loaded', 'false');
    page.setAttribute('data-page-number', num);
    canvas.setAttribute('id', `page${num}`);
    page.appendChild(wrapper);
    page.appendChild(textLayer);
    wrapper.appendChild(canvas);
    this.pdfViewer.appendChild(page);
    return page;
  }

  configureAnnotator() {
    const that = this;
    const app = new annotator.App();

    app.include(annotator.ui.main, {
      element: this.pdfViewerContainer,
      editorExtensions: [
        (editor) => {
          that.annotatorEditor = editor;
        },
      ],
    });

    app.include(annotator.storage.http, {
      prefix: this.props.annotationPrefix,
      urls: {
        create: this.props.annotationCreateURL,
        update: this.props.annotationUpdateURL,
        destroy: this.props.annotationDestroyURL,
        search: this.props.annotationSearchURL,
      },
      onError: (msg, xhr) => {
        that.setState((state) => ({
          ...state,
          errorMessage: xhr.responseJSON.detail,
          processing: false,
        }));
      },
    });

    app.include(annotator.authz.acl);

    app.include(this.annotatorHooks, { props: this.props });

    app.start().then(() => {
      app.annotations.store.setHeader(
        'X-CSRFToken',
        storage.getCookie('csrftoken2')
      );
      // Mokey patch to add xhrFields.
      const _apiRequestOptions = app.annotations.store._apiRequestOptions;
      app.annotations.store._apiRequestOptions = function () {
        const options = _apiRequestOptions.apply(this, arguments);
        options.xhrFields = { withCredentials: true };
        return options;
      };
      // End monkey patch

      app.ident.identity = that.context.user;
      app.annotations.load();
    });
  }

  annotatorHooks(options) {
    const that = this;
    const props = options.props;

    const copyExtraAnnotationProps = (annotation) => {
      Object.keys(props.annotationExtraProps).forEach((key) => {
        annotation[key] = props.annotationExtraProps[key];
      });
      annotation.json = JSON.stringify(annotation);
    };

    return {
      start: (app) => {
        that.annotatorApp = app;
      },
      annotationsLoaded: (annotations) => {
        if (that._mounted) {
          if (props.annotationsLoaded) {
            props.annotationsLoaded(annotations);
          }
          annotations.forEach((ann) => {
            // Set the same user object, so authz works correctly.
            if (ann.user.email === that.context.user.email) {
              ann.user = that.context.user;
            }
            that.setHighlightColor(ann);
          });
          that.setState(
            (state) => ({
              ...state,
              processing: false,
              annotations,
            }),
            () => that.sortAnnotations()
          );
        }
      },
      beforeAnnotationCreated: (annotation) => {
        if (that._mounted) {
          copyExtraAnnotationProps(annotation);
          that.setState((state) => ({ ...state, annotation }));
        }
      },
      annotationCreated: (annotation) => {
        if (that._mounted) {
          if (props.annotationCreated) {
            props.annotationCreated(annotation);
          }
          // Set the same user object, so authz works correctly.
          if (
            !annotation.user ||
            annotation.user.email === that.context.user.email
          ) {
            annotation.user = that.context.user;
          }
          that.setState((state) => ({ ...state, annotation: null }));
          this.addAnnotationInPanel(annotation);
        }
      },
      beforeAnnotationUpdated: (annotation) => {
        if (that._mounted) {
          copyExtraAnnotationProps(annotation);
          that.setState((state) => ({ ...state, annotation }));
        }
      },
      annotationUpdated: (annotation) => {
        if (that._mounted) {
          if (props.annotationUpdated) {
            props.annotationUpdated(annotation);
          }
          that.setState((state) => ({ ...state, annotation: null }));
          that.updateAnnotationInPanel(annotation);
        }
      },
      beforeAnnotationDeleted: (annotation) => {
        if (that._mounted) {
          if (props.beforeAnnotationDeleted) {
            props.beforeAnnotationDeleted(annotation);
          }
        }
      },
      annotationDeleted: (annotation) => {
        if (that._mounted) {
          if (props.annotationDeleted) {
            props.annotationDeleted(annotation);
          }
          that.removeAnnotationInPanel(annotation);
        }
      },
    };
  }

  removePages() {
    if (this.state.pdfDocument) {
      for (let i = 1; i <= this.state.pdfDocument.numPages; i++) {
        const oldPage = document.getElementById(`pageContainer${i}`);
        if (oldPage) {
          oldPage.remove();
        }
      }
    }
  }

  setHighlightColor(annotation) {
    const elements = document.querySelectorAll(
      `[data-annotation-id="${annotation.id}"]`
    );
    [].forEach.call(elements, (el) => {
      el.style.backgroundColor = annotation.color;
    });
  }

  addAnnotationInPanel(annotation) {
    if (!annotation.id) {
      annotation.id = new Date().getTime(); //temp id
    }
    const annotations = this.state.annotations.slice();
    annotations.push(annotation);
    this.setState(
      (state) => ({ ...state, annotations }),
      () => {
        const elements = document.querySelectorAll('.annotator-hl');
        [].forEach.call(elements, (el) => {
          if (!el.hasAttribute('data-annotation-id')) {
            el.setAttribute('data-annotation-id', annotation.id);
          }
        });
        this.sortAnnotations();
        this.setHighlightColor(annotation);
      }
    );
  }

  sortAnnotations() {
    const orderedIds = [];
    const elements = document.querySelectorAll('.annotator-hl');
    [].forEach.call(elements, (el) => {
      const id = el.getAttribute('data-annotation-id');
      if (orderedIds.indexOf(id) === -1) {
        orderedIds.push(id);
      }
    });
    const orderedAnnotations = [];
    orderedIds.forEach((id) => {
      const ann = this.state.annotations.find((a) => a.id === Number(id));
      orderedAnnotations.push(ann);
    });
    this.setState((state) => ({ ...state, annotations: orderedAnnotations }));
  }

  updateAnnotationInPanel(annotation) {
    const annotations = this.state.annotations.slice();
    const index = annotations.findIndex((a) => a.id === annotation.id);
    annotations[index] = annotation;
    this.setState((state) => ({ ...state, annotations }));
    this.setHighlightColor(annotation);
  }

  removeAnnotationInPanel(annotation) {
    const annotations = this.state.annotations.slice();
    const index = annotations.findIndex((a) => a.id === annotation.id);
    annotations.splice(index, 1);
    this.setState((state) => ({ ...state, annotations }));
  }

  onZoomInClick() {
    const newZoom = this.state.scale + 0.25;
    if (this.state.scale < 2) {
      this.setState(
        (state) => ({
          ...state,
          scale: newZoom,
          processing: true,
        }),
        () => this.loadAllPages(this.state.scale)
      );
    }
  }

  onZoomOutClick() {
    const newZoom = this.state.scale - 0.25;
    if (this.state.scale > 0.25) {
      this.setState(
        (state) => ({
          ...state,
          scale: newZoom,
          processing: true,
        }),
        () => this.loadAllPages(this.state.scale)
      );
    }
  }

  renderPDFViewer() {
    const { i18n } = this.props;
    let title = i18n.t('Documento sem título');
    if (this.props.title) {
      title = this.props.title;
    }
    return (
      <div className="panel panel-flat panel-contrast">
        <div className="panel-heading panel-heading-contrast">
          {title}
          {this.props.authors}
          <div className="tools dropdown">
            <i
              className="icon mdi mdi-zoom-out"
              onClick={this.onZoomOutClick}
            />
            &nbsp;
            <i className="icon mdi mdi-zoom-in" onClick={this.onZoomInClick} />
          </div>
        </div>
        <div className="panel-body ff-scrollbar">
          <div ref={(el) => (this.pdfViewerContainer = el)}>
            <div
              id="viewer"
              className="pdfViewer"
              ref={(el) => (this.pdfViewer = el)}
            />
          </div>
        </div>

        <Modal
          show={!!this.state.errorMessage}
          onCancel={() =>
            this.setState((state) => ({ ...state, errorMessage: null }))
          }>
          <AlertError>{this.state.errorMessage}</AlertError>
        </Modal>

        {this.state.annotation && (
          <AnnotatorEditor
            annotation={this.state.annotation}
            annotatorApp={this.annotatorApp}
            annotatorEditor={this.annotatorEditor}
            onCancel={() =>
              this.setState((state) => ({ ...state, annotation: null }))
            }
            onSave={() =>
              this.setState((state) => ({ ...state, annotation: null }))
            }
            fetchURL={
              this.props.annotationPrefix + this.props.annotationFetchURL
            }
            createURL={
              this.props.annotationPrefix + this.props.annotationCreateURL
            }
            updateURL={
              this.props.annotationPrefix + this.props.annotationUpdateURL
            }
          />
        )}
      </div>
    );
  }

  renderPDFViewerWithAnnotations() {
    const { i18n } = this.props;
    return (
      <ReflexViewport>
        <ReflexContainer orientation="vertical">
          <ReflexElement className="ff-scrollbar" flex={0.65}>
            {this.renderPDFViewer()}
          </ReflexElement>

          <ReflexSplitter propagate={true} />

          <ReflexElement className="ff-scrollbar" style={{ padding: '10px' }}>
            {this.state.annotations.length > 0 &&
              this.state.annotations.map((ann) => {
                return (
                  <AnnotationPanel
                    annotation={ann}
                    annotatorApp={this.annotatorApp}
                    key={ann.id}
                  />
                );
              })}

            {this.state.annotations.length === 0 && !this.state.processing && (
              <AlertInfo>
                {i18n.t(
                  'Selecione o texto no PDF ao lado para inserir comentários.'
                )}
              </AlertInfo>
            )}
          </ReflexElement>
        </ReflexContainer>
      </ReflexViewport>
    );
  }

  render() {
    let body;
    if (this.props.showAnnotations) {
      body = this.renderPDFViewerWithAnnotations();
    } else {
      body = this.renderPDFViewer();
    }

    return <Waiting isProcessing={this.state.processing}>{body}</Waiting>;
  }
}

PDFViewer.propTypes = {
  withCredentials: PropTypes.bool,
  title: PropTypes.string,
  authors: PropTypes.array,
  showAnnotations: PropTypes.bool,
  /**
   * The PDF url.
   */
  url: PropTypes.string.isRequired,
  /**
   * Function called after page changed. Receives the page number.
   */
  onPageChange: PropTypes.func,
  annotationPrefix: PropTypes.string,
  annotationCreateURL: PropTypes.string,
  annotationFetchURL: PropTypes.string,
  annotationUpdateURL: PropTypes.string,
  annotationDestroyURL: PropTypes.string,
  annotationSearchURL: PropTypes.string,
  /**
   * Extra proeprties to be set in the annotation object during
   * REST operations.
   */
  annotationExtraProps: PropTypes.object,
  annotationsLoaded: PropTypes.func,
  beforeAnnotationCreated: PropTypes.func,
  annotationCreated: PropTypes.func,
  beforeAnnotationUpdated: PropTypes.func,
  annotationUpdated: PropTypes.func,
  beforeAnnotationDeleted: PropTypes.func,
  annotationDeleted: PropTypes.func,
};

PDFViewer.defaultProps = {
  annotationPrefix: `${Config.apiHost}`,
  withCredentials: true,
  showAnnotations: true,
};

PDFViewer.contextType = AppContext;

export default withNamespaces()(PDFViewer);
