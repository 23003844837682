import React, { Component } from 'react';
import FacebookPixel from './FacebookPixel';
import GetSiteControl from './GetSiteControl';
import { AppContext } from '../context/global';
import { withNamespaces } from 'react-i18next';
import { hasScriptTag } from '../common/htmlutils';

class LoadTrackers extends Component {
  constructor(props) {
    super(props);
    this.loadGetSiteControl = this.loadGetSiteControl.bind(this);
    this.loadFacebook = this.loadFacebook.bind(this);
  }

  componentDidMount() {
    try {
      this.loadGetSiteControl();
      this.loadFacebook();
    } catch (error) {
      console.error(error);
    }
  }

  loadGetSiteControl() {
    const thisComponent = this;
    const src = '//widgets.getsitecontrol.com/29571/script.js';
    if (typeof _gscq === 'undefined' && !hasScriptTag(src)) {
      (function (w, i, d, g, e, t, s) {
        w[d] = w[d] || [];
        t = i.createElement(g);
        t.src = e;
        t.async = 1;
        t.defer = 1;
        t.onload = () => {
          GetSiteControl.sendTrackingInfo(thisComponent.context);
        };
        t.onerror = () => {};
        s = i.getElementsByTagName(g)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, '_gscq', 'script', src);
    }
  }

  loadFacebook() {
    const src = 'https://connect.facebook.net/en_US/fbevents.js';
    if (typeof fbq === 'undefined' && !hasScriptTag(src)) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.src = v;
        t.async = 1;
        t.defer = 1;
        t.onload = () => {
          FacebookPixel.sendPageView();
        };
        t.onerror = () => {};
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', src);
      fbq('init', '871361676315812');
    }
  }

  render() {
    return null;
  }
}

LoadTrackers.contextType = AppContext;

export default withNamespaces()(LoadTrackers);
