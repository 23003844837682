import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Textarea from '../form/Textarea';
import Modal from '../../containers/Modal';
import Button from '../Button';
import Ajax from '../../ajax';
import {
  clearDjangoValidationMessages,
  showDjangoValidationMessages,
} from '../../validation';
import { AlertError } from '../Alert';
import { CirclePicker as ColorPicker } from 'react-color';
import Config from '../../../config';
import HtmlUtils from '../../htmlutils';
import storage from '../../storage';
import Checkbox from '../form/Checkbox';
import AnnotationQuote from './AnnotationQuote';

const DEFAULT_COLOR = '#ffff00';
const LAST_CHOSE_COLOR_KEY = 'ffLastChoseColor';

class AnnotatorEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annotation: {
        ...this.props.annotation,
        color: storage.getItem(
          LAST_CHOSE_COLOR_KEY,
          this.props.annotation.color || DEFAULT_COLOR
        ),
      },
      processing: false,
      errorMessage: null,
      templates: null,
      saveAsTemplate: false,
    };
    this.onCommentChange = this.onCommentChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChangeColor = this.onChangeColor.bind(this);
    this.onSelectTemplate = this.onSelectTemplate.bind(this);
    this.onDeleteTemplate = this.onDeleteTemplate.bind(this);
    this.fetchTemplates = this.fetchTemplates.bind(this);
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;

    if (this.props.annotatorEditor) {
      this.props.annotatorEditor.element[0].style.display = 'none';
    }

    if (this.props.annotation.id) {
      this.fetchAnnotation();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchAnnotation() {
    this.setState((state) => ({ ...state, processing: true }));
    const url = this.props.fetchURL.replace('{id}', this.state.annotation.id);
    Ajax.get(url)
      .done((annotation) => {
        this.setState((state) => ({
          ...state,
          annotation: {
            ...annotation,
            color: annotation.color || DEFAULT_COLOR,
          },
        }));
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  fetchTemplates() {
    this.setState((state) => ({ ...state, processing: true }));
    const url = `${Config.apiHost}annotation-templates/`;
    Ajax.get(url)
      .done((templates) => {
        this.setState((state) => ({ ...state, templates }));
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  onCommentChange(e) {
    e.persist();
    this.setState((state) => ({
      ...state,
      annotation: {
        ...state.annotation,
        text: e.target.value,
      },
    }));
  }

  onChangeColor(color) {
    console.log(color);
    this.setState((state) => ({
      ...state,
      annotation: {
        ...state.annotation,
        color: color.hex,
      },
    }));
    storage.setItem(LAST_CHOSE_COLOR_KEY, color.hex);
  }

  onSelectTemplate(template) {
    this.setState((state) => ({
      ...state,
      annotation: {
        ...state.annotation,
        color: template.color,
        text: template.text,
      },
    }));
  }

  onDeleteTemplate(template) {
    this.setState((state) => ({ ...state, processing: true }));
    const url = `${Config.apiHost}annotation-templates/${template.id}/`;
    Ajax.delete_(url)
      .done(() => {
        this.fetchTemplates();
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  onSave() {
    clearDjangoValidationMessages();
    this.setState((state) => ({ ...state, processing: true }));

    if (this.state.saveAsTemplate) {
      const url = `${Config.apiHost}annotation-templates/`;
      const data = {
        color: this.state.annotation.color,
        text: this.state.annotation.text,
      };
      Ajax.post(url, data);
    }

    let xhr;

    if (this.state.annotation.id) {
      xhr = Ajax.put(
        this.props.updateURL.replace('{id}', this.state.annotation.id),
        this.state.annotation
      );
    } else {
      xhr = Ajax.post(this.props.createURL, this.state.annotation);
    }

    xhr.done((annotation) => {
      this.props.annotation.text = annotation.text;
      this.props.annotation.color = annotation.color;

      if (this.props.annotatorApp) {
        if (this.props.annotation.id) {
          this.props.annotatorApp.runHook('annotationUpdated', [
            this.props.annotation,
          ]);
        } else {
          this.props.annotation.id = annotation.id;
          this.props.annotatorApp.runHook('annotationCreated', [
            this.props.annotation,
          ]);
        }
      }

      if (this._mounted) {
        this.setState((state) => ({
          ...state,
          processing: false,
          errorMessage: null,
        }));
      }

      if (this.props.onSave) {
        this.props.onSave();
      }
    });
    xhr.fail((resp) => {
      this.setState((state) => ({
        ...state,
        processing: false,
        errorMessage: resp.responseJSON.detail,
      }));
      showDjangoValidationMessages(resp.responseJSON);
    });
  }

  render() {
    const { i18n, onCancel } = this.props;

    const modalFooter = (
      <>
        <div className="pull-left">
          <Checkbox
            asFormGroup={false}
            id="saveAsTemplate"
            checked={this.state.saveAsTemplate}
            onChange={(e) => {
              e.persist();
              this.setState((state) => ({
                ...state,
                saveAsTemplate: e.target.checked,
              }));
            }}
            label={i18n.t('Salvar comentário como template')}
            title={i18n.t(
              'Salva o comentário atual no documento e o adiciona a lista de templates'
            )}
          />
        </div>
        <Button type="default" onClick={onCancel} size="lg">
          {i18n.t('Cancelar')}
        </Button>
        <Button
          type="primary"
          size="lg"
          onClick={this.onSave}
          title={i18n.t('Salva o comentário')}>
          {i18n.t('Salvar')}
        </Button>
      </>
    );

    return (
      <Modal
        isProcessing={this.state.processing}
        title={i18n.t('Anotação')}
        show={true}
        onCancel={onCancel}
        footer={modalFooter}
        style={{ zIndex: 30000 }}>
        {this.state.errorMessage && (
          <AlertError>{this.state.errorMessage}</AlertError>
        )}

        <ColorPicker
          color={this.state.annotation.color}
          onChange={this.onChangeColor}
          colors={[
            DEFAULT_COLOR,
            '#FF6900',
            '#00D084',
            '#0693E3',
            '#9900EF',
            '#7a8188',
          ]}
          width="500px"
          circleSize={30}
        />
        <br />
        <AnnotationQuote
          text={this.state.annotation.quote}
          backgroundColor={this.state.annotation.color}
        />
        <br />
        <br />
        <Textarea
          id="annotation-comment"
          placeholder={i18n.t('Insira um comentário... (opcional)')}
          value={this.state.annotation.text}
          onChange={this.onCommentChange}
          rows={3}
          autoFocus={true}
        />
        <h4>
          <a role="button" onClick={this.fetchTemplates}>
            {i18n.t('Ou escolha um template de comentário')}
          </a>
        </h4>
        {this.state.templates && (
          <div className="well well-sm">
            {this.state.templates.length === 0 && (
              <p>
                {i18n.t('Você ainda não possui templates.')}{' '}
                {i18n.t(
                  'Para criar um template, faça uma anotação e escolha a opção "Salvar como template".'
                )}
              </p>
            )}
            <table className="table table-condensed">
              <tbody>
                {this.state.templates.map((t) => {
                  return (
                    <tr key={t.id}>
                      <td width="1%">
                        <span
                          className="label"
                          style={{ backgroundColor: t.color }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </td>
                      <td>{t.truncated_text}</td>
                      <td width="1%" nowrap="nowrap">
                        <Button
                          onClick={() => this.onSelectTemplate(t)}
                          size="xs"
                          type="default">
                          {i18n.t('Selecionar')}
                        </Button>
                        <Button
                          size="xs"
                          type="default"
                          title={i18n.t('Excluir template permanentemente')}
                          onClick={() => this.onDeleteTemplate(t)}>
                          <i className="mdi mdi-delete" />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Modal>
    );
  }
}

AnnotatorEditor.propTypes = {
  annotation: PropTypes.object.isRequired,
  annotatorApp: PropTypes.object,
  annotatorEditor: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  createURL: PropTypes.string.isRequired,
  fetchURL: PropTypes.string.isRequired,
  updateURL: PropTypes.string.isRequired,
};

export default withNamespaces()(AnnotatorEditor);
