import React from 'react';
import { withNamespaces } from 'react-i18next';
import 'react-reflex/styles.css';
import Ajax from '../common/ajax';
import PDFViewer from '../common/components/PDFViewer/PDFViewer';
import PDFViewerPdfjs from '../common/components/PDFViewer/PDFViewerPdfjs';
import Content from '../common/containers/Content';
import PrivatePage from '../common/containers/PrivatePage';
import Config from '../config';

class ReferencePDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: {},
    };
  }

  componentDidMount() {
    this.fetchReference();
  }

  fetchReference() {
    const url = `${Config.apiHost}references/${this.props.match.params.referenceId}/`;
    Ajax.get(url).done((reference) => {
      this.setState((state) => ({
        ...state,
        reference,
      }));
    });
  }

  render() {
    const { i18n } = this.props;
    const { reference } = this.state;
    const isV2 = reference.annotator_version === 'v2';
    const urls = {
      annotationCreateURL: 'reference-annotations/',
      annotationFetchURL: 'reference-annotations/{id}/',
      annotationUpdateURL: 'reference-annotations/{id}/',
      annotationDestroyURL: 'reference-annotations/{id}/',
      annotationSearchURL: `reference-annotations/search/?reference=${reference.id}`,
    };
    return (
      <PrivatePage title={i18n.t('Visualizar referência')}>
        <Content noPadding={true}>
          {reference.pdf && !isV2 && (
            <PDFViewer
              title={reference.title}
              authors={reference.authors}
              url={reference.pdf}
              {...urls}
              annotationExtraProps={{
                reference: reference.id,
              }}
              withCredentials={false}
            />
          )}
          {reference.pdf && isV2 && (
            <PDFViewerPdfjs
              url={reference.pdf}
              {...urls}
              annotationExtraProps={{
                reference: reference.id,
              }}
              withCredentials={false}
            />
          )}
        </Content>
      </PrivatePage>
    );
  }
}

export default withNamespaces()(ReferencePDFViewer);
