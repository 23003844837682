import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const ICONS_MAP = {
  success: 'mdi-check',
  info: 'mdi-info-outline',
  primary: 'mdi-info-outline',
  warning: 'mdi-alert-triangle',
  danger: 'mdi-close-circle-o',
};

class Alert extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.children) {
      return null;
    }

    const { i18n, type, onClose, children, className, style, ...rest } = this.props;
    const realType = this.props.type === 'info' ? 'primary' : type;

    return (
      <div
        role="alert"
        ref={(el) => (this.alert = el)}
        className={`alert alert-${realType} alert-icon alert-icon-${
          this.props.type === 'info' ? 'colored' : 'border'
        } ${className ? className : ''}`}
        style={style}
      >
        <div className="icon">
          <span className={`mdi ${ICONS_MAP[realType]}`} />
        </div>
        <div className="message" style={{ padding: '10px' }}>
          {onClose && (
            <button
              type="button"
              aria-label={i18n.t('Fechar')}
              className="close"
              onClick={onClose}
              style={{ top: '15px' }}
            >
              <span aria-hidden="true" className="mdi mdi-close" />
            </button>
          )}
          {children}
        </div>
      </div>
    );
  }
}

Alert.propTypes = {
  type: PropTypes.oneOf(Object.keys(ICONS_MAP)).isRequired,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  type: 'success',
};

export default withNamespaces()(Alert);

export class AlertSuccess extends React.Component {
  render() {
    return (
      <Alert {...this.props} type="success">
        {this.props.children}
      </Alert>
    );
  }
}

AlertSuccess.propTypes = {
  onClose: PropTypes.func,
};

AlertSuccess = withNamespaces()(AlertSuccess);

export class AlertError extends React.Component {
  render() {
    return (
      <Alert {...this.props} type="danger">
        {this.props.children}
      </Alert>
    );
  }
}

AlertError.propTypes = {
  onClose: PropTypes.func,
};

AlertError = withNamespaces()(AlertError);

export class AlertInfo extends React.Component {
  render() {
    return (
      <Alert {...this.props} type="info">
        {this.props.children}
      </Alert>
    );
  }
}

AlertInfo.propTypes = {
  onClose: PropTypes.func,
};

AlertInfo = withNamespaces()(AlertInfo);

export class AlertWarning extends React.Component {
  render() {
    return (
      <Alert {...this.props} type="warning">
        {this.props.children}
      </Alert>
    );
  }
}

AlertWarning.propTypes = {
  onClose: PropTypes.func,
};

AlertWarning = withNamespaces()(AlertWarning);

export class AlertFromResponse extends React.Component {
  render() {
    if (!this.props.response) {
      return null;
    }

    if (this.props.response.detail) {
      return <AlertSuccess onClose={this.props.onClose}>{this.props.response.detail}</AlertSuccess>;
    } else if (this.props.response.responseJSON) {
      return <AlertError onClose={this.props.onClose}>{this.props.response.responseJSON.detail}</AlertError>;
    }

    return null;
  }
}

AlertFromResponse.propTypes = {
  /**
   * A json with response data or jqXHR object. If a json, it must have the
   * 'detail' attribute with the message and it is assumed that is a success
   * message.
   */
  response: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

AlertFromResponse = withNamespaces()(AlertFromResponse);
