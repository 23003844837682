import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import AnnotationQuote from './AnnotationQuote';

class AnnotationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllText: false,
    };
    this.scrollToAnnotation = this.scrollToAnnotation.bind(this);
  }

  scrollToAnnotation(id) {
    const panels = document.querySelectorAll('.annotator-hl');
    [].some.call(panels, (el) => {
      if (el.getAttribute('data-annotation-id') === String(id)) {
        el.scrollIntoView();
        return true;
      }
    });
  }

  render() {
    const { i18n, annotation } = this.props;

    let annotationObject = {};

    if (annotation.json) {
      annotationObject = {
        ...JSON.parse(annotation.json),
      };
    }

    if (!annotationObject.quote && !annotationObject.text) {
      return null;
    }

    let { quote } = annotationObject;
    let showQuoteToggler = false;
    const maxQuoteSize = 200;
    if (quote && quote.length > maxQuoteSize && !this.state.showAllText) {
      quote = quote.slice(0, maxQuoteSize) + '...';
      showQuoteToggler = true;
    }

    return (
      <>
        <div className="panel panel-border panel-contrast" key={annotation.id}>
          <div className="panel-heading panel-heading-contrast">
            <div className="row">
              <div className="col-md-10">{annotation.text}</div>
              <div className="col-md-2">
                <div className="tools">
                  <span
                    className="icon mdi mdi-edit"
                    onClick={() => this.props.onEdit()}
                  />
                  <span
                    className="icon mdi mdi-delete"
                    onClick={() => {
                      if (
                        confirm(i18n.t('Confirma a exclusão desse comentário?'))
                      ) {
                        this.props.onRemove();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <AnnotationQuote text={quote} backgroundColor={annotation.color} />{' '}
            {showQuoteToggler && (
              <a
                role="button"
                onClick={() =>
                  this.setState((state) => ({
                    ...state,
                    showAllText: !state.showAllText,
                  }))
                }>
                {i18n.t('mais')}
              </a>
            )}
          </div>
        </div>
      </>
    );
  }
}

AnnotationPanel.propTypes = {
  annotation: PropTypes.object.isRequired,
  annotatorApp: PropTypes.object,
  onEdit: PropTypes.func,
};

export default withNamespaces()(AnnotationPanel);
