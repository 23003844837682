import React from 'react';
import ReactDOM from 'react-dom';

export default class RenderInBody extends React.PureComponent {
    componentDidMount() {
        this.div = document.createElement("div");
        document.body.appendChild(this.div);
        this._renderLayer();
    }

    componentDidUpdate() {
        this._renderLayer();
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.div);
        document.body.removeChild(this.div);
    }

    _renderLayer() {
        ReactDOM.render(this.props.children, this.div);
    }

    render() {
        return null;
    }
}