export default class GetSiteControl {
  static getPage() {
    const { location } = window;
    return `${location.origin}${location.pathname}${location.search}`;
  }

  static trackPage() {
    try {
      GetSiteControl.push(['trackPage', GetSiteControl.getPage()]);
    } catch (error) {
      console.error(error);
    }
  }

  static setTargetingParameter(name, value) {
    try {
      GetSiteControl.push(['targeting', name, value]);
    } catch (error) {
      console.error(error);
    }
  }

  static setTemplateParameter(name, value) {
    try {
      GetSiteControl.push(['template', name, value]);
    } catch (error) {
      console.error(error);
    }
  }

  static show(widgetId) {
    try {
      GetSiteControl.push(['show', widgetId]);
    } catch (error) {
      console.error(error);
    }
  }

  static push(data) {
    try {
      if (typeof _gscq !== 'undefined') {
        _gscq.push(data);
      } else {
        console.warn('GetSiteControl not loaded');
      }
    } catch (error) {
      console.error(error);
    }
  }

  static sendTrackingInfo(context) {
    try {
      const { current_plan_name, is_free, is_expiration_close, is_expired, days_to_expire, upgrade_next_plan } =
        context;

      // Targeting parameters
      GetSiteControl.setTargetingParameter('plan_name', current_plan_name);
      GetSiteControl.setTargetingParameter('is_free', is_free);
      GetSiteControl.setTargetingParameter('is_expiration_close', is_expiration_close);
      GetSiteControl.setTargetingParameter('is_expired', is_expired);
      GetSiteControl.setTargetingParameter('can_upgrade', !!upgrade_next_plan);

      // Template parameters
      GetSiteControl.setTemplateParameter('plan_name', current_plan_name);
      GetSiteControl.setTemplateParameter('days_to_expire', days_to_expire);

      // Page
      GetSiteControl.trackPage();
    } catch (error) {
      console.error(error);
    }
  }
}
