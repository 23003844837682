import React from 'react';
import PropTypes from 'prop-types';
import Waiting from './Waiting';
import RenderInBody from './RenderInBody';
import './modal.css';

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.onCancelHandler = this.onCancelHandler.bind(this);
    this.refModalBody = React.createRef();
    this.onModalElementRef = this.onModalElementRef.bind(this);
    this._modalElement = null;
  }

  onModalElementRef(element) {
    if (element) {
      this._modalElement = element;
      const $element = $(this._modalElement);
      if (this.props.onShown) {
        $element.on('shown.bs.modal', this.props.onShown);
      }
      if (this.props.onHidden) {
        $element.on('hidden.bs.modal', this.props.onHidden);
      }
      $element.on('hide.bs.modal', this.props.onCancel);
      $element.modal('show');
    } else {
      $(this._modalElement).modal('hide');
      this._modalElement = null;
    }
  }

  onCancelHandler(e) {
    this.props.onCancel(e);
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    let large = '';
    let larger = '';
    let dialogStyle = {};
    let contentStyle = {};
    let customWidthObj = {};

    let {
      children,
      footer,
      renderInBody,
      title,
      subtitle,
      bodyMinHeight,
      bodyMaxHeight,
      width,
      customWidth,
      fullScreen,
      fullColor,
      headerFullColor,
      modalClasses,
      style,
      isProcessing,
      closeOnClickOutside,
      bodyStyle = {},
      footerStyle = {},
    } = this.props;

    if (customWidth) {
      customWidthObj = {
        width: customWidth,
        maxWidth: '99vw',
      };
      large = 'custom-width';
    } else if (width === 'large') {
      large = 'custom-width';
    } else if (width === 'full') {
      larger = 'full-width';
    }

    if (fullScreen) {
      bodyMaxHeight = 'calc(83vh)';
      contentStyle = { ...contentStyle, margin: '10px auto' };
      dialogStyle = { ...dialogStyle, margin: '10px auto', width: '95%' };
      footerStyle = { ...footerStyle, padding: '10px' };
    }

    const classes = ['modal'];
    if (fullColor) {
      classes.push('modal-full-color', `modal-full-color-${fullColor}`);
    } else if (headerFullColor) {
      classes.push('colored-header', `colored-header-${headerFullColor}`);
    }

    const modal = (
      <div
        id={this.props.id}
        role="dialog"
        className={`${classes.join(' ')} ${large} ${modalClasses}`}
        ref={this.onModalElementRef}
        data-backdrop={closeOnClickOutside ? 'true' : 'static'}
        style={{ zIndex: 10000, ...style }}
      >
        <div className={`modal-dialog ${larger}`} style={{ ...dialogStyle, ...customWidthObj }}>
          <div className="modal-content" style={{ ...contentStyle, ...customWidthObj }}>
            <Waiting isProcessing={isProcessing}>
              <div className="modal-header">
                {this.props.onCancel && (
                  <button type="button" aria-hidden="true" className="close" onClick={this.onCancelHandler}>
                    <span className="mdi mdi-close"> </span>
                  </button>
                )}
                <h3>
                  {title} <small>{subtitle}</small>
                </h3>
              </div>
              <div
                className="modal-body ff-scrollbar"
                ref={this.refModalBody}
                style={{
                  minHeight: bodyMinHeight,
                  maxHeight: bodyMaxHeight,
                  overflowY: 'auto',
                  ...bodyStyle,
                }}
              >
                {children}
              </div>
              {footer && (
                <div className="modal-footer" style={footerStyle}>
                  {footer}
                </div>
              )}
            </Waiting>
          </div>
        </div>
      </div>
    );

    if (!renderInBody) {
      return modal;
    } else {
      return <RenderInBody>{modal}</RenderInBody>;
    }
  }
}

Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  show: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool,
  modalClasses: PropTypes.string,
  width: PropTypes.oneOf(['default', 'large', 'full', 'extra-full']),
  bodyMinHeight: PropTypes.string,
  bodyMaxHeight: PropTypes.string,
  customWidth: PropTypes.string,
  fullScreen: PropTypes.bool,
  footer: PropTypes.element,
  bodyStyle: PropTypes.object,
  footerStyle: PropTypes.object,
  renderInBody: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
  headerFullColor: PropTypes.oneOf(['default', 'primary', 'success', 'danger', 'warning']),
  fullColor: PropTypes.oneOf(['default', 'primary', 'success', 'danger', 'warning']),
  /**
   * Function executed when the user clicks on close button, click outside
   * the modal or press ESC key.
   */
  onCancel: PropTypes.func,
  /**
   * Function executed after the modal is shown.
   */
  onShown: PropTypes.func,
  /**
   * Function executed after the modal is hidden.
   */
  onHidden: PropTypes.func,
};

Modal.defaultProps = {
  show: false,
  isProcessing: false,
  width: 'default',
  modalClasses: '',
  renderInBody: true,
  bodyStyle: {},
  footerStyle: {},
  bodyMaxHeight: 'calc(70vh)',
  closeOnClickOutside: false,
};
