import React, {Component} from 'react';
import Ajax from "../../common/ajax";
import Config from "../../config";
import GoogleAnalytics from "../../integrations/GoogleAnalytics";
import FacebookPixel from "../../integrations/FacebookPixel";
import {AppContext} from "../../context/global";

class EcommerceTracking extends Component {

    componentDidMount() {
        Ajax.get(`${Config.apiHost}checkouts/not_tracked_paid_checkouts/`).done(checkouts => {
            const {user} = this.context;
            checkouts.forEach(checkout => {
                GoogleAnalytics.logEcommerce(user, checkout);
                FacebookPixel.sendEcommerce(checkout);
                Ajax.post(`${Config.apiHost}checkouts/${checkout.id}/set_tracked/`);
            });
        });
    }

    render() {
        return null;
    }
}

EcommerceTracking.contextType = AppContext;

export default EcommerceTracking;
