import Mark from 'mark.js';

export function hasScriptTag(src) {
  return !!document.querySelector('script[src="' + src + '"]');
}

const HtmlUtils = {
  stripHtmlTags: function (value) {
    return $('<span>' + value + '</span>').text();
  },

  scrollFirstScrollableToTop: function (node) {
    if (node == null) {
      return null;
    }
    if (node.scrollHeight > node.clientHeight) {
      if (node.nodeName === 'BODY') {
        window.scrollTo(0, 0);
      } else {
        node.scrollTop = 0;
      }
      return node;
    } else {
      return this.scrollFirstScrollableToTop(node.parentNode);
    }
  },

  highlight: function (keywords, contextElement) {
    if (!keywords) {
      return;
    }

    keywords = keywords.trim();

    let separateWordSearch = true;

    if (keywords.startsWith('"') && keywords.endsWith('"')) {
      separateWordSearch = false;
    } else {
      keywords = keywords
        .split(' ')
        .filter((w) => w.length >= 2)
        .join(' ');
    }

    keywords = keywords.replaceAll('"', '');

    const markJs = new Mark(contextElement);

    markJs.unmark({
      done: () => {
        if (keywords) {
          markJs.mark(keywords, {
            acrossElements: true,
            accuracy: 'exactly',
            ignorePunctuation: ':;.,-–—‒_(){}[]!\'"+='.split(''),
            separateWordSearch,
          });
        }
      },
    });
  },

  removeHighlight: function (contextElement) {
    const markJs = new Mark(contextElement);
    markJs.unmark();
  },

  convertHex2RGBA: function (hex, opacity = 1) {
    let rgba = '';
    if (hex) {
      hex = hex.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      rgba = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }
    return rgba;
  },
};

export default HtmlUtils;

export function getIframeDocument(iframe) {
  return (
    iframe.contentWindow ||
    iframe.contentDocument.document ||
    iframe.contentDocument
  ).document;
}
