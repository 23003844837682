import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class AnnotationQuote extends Component {
  render() {
    const quotaTransparency = '40';
    return (
      <span
        className="text-muted"
        style={{
          backgroundColor: this.props.backgroundColor + quotaTransparency,
          padding: '3px 10px',
          fontSize: '16px',
        }}>
        <i className="mdi mdi-quote" /> <i>{this.props.text}</i>{' '}
        <i className="mdi mdi-quote" />
      </span>
    );
  }
}

AnnotationQuote.propTypes = {
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};
